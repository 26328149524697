<template>
  <div>
    <div class="flex">
      <h2 class="text-xl font-bold text-base mt-5">{{i18n('recentMaterials.videos.title')}}</h2>
      <h2 class="mt-5" :class="isRTL ? 'mr-auto' : 'ml-auto'">{{i18n('recentMaterials.videos.seeMore')}}</h2>
    </div>

    <div class="intro-y box p-5 mt-5 flex grid grid-cols-12 dark:bg-dark-8">
      <div class="col-span-4" v-for="video in 3" :key="video">
        <div class="px-2">
          <div class="h-full image-fit rounded-md overflow-hidden">
            <VuePlayerVideo
              src="https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"
            />
          </div>
          <div>
            <h2 class="text-xl mt-5">Video Title Is very long long</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePlayerVideo from 'vue3-player-video'

export default {
  components: {
    VuePlayerVideo
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style></style>
