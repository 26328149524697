<template>
  <div>
    <div class="flex">
      <h2 class="text-xl font-bold text-base mt-5">{{i18n('recentMaterials.articles.title')}}</h2>
      <h2 class="mt-5" :class="isRTL ? 'mr-auto' : 'ml-auto'">{{i18n('recentMaterials.articles.seeMore')}}</h2>
    </div>
    <div class="intro-y grid grid-cols-12 gap-6 mt-5">
      <div v-for="(faker, fakerKey) in $_.take($f(), 3)" :key="fakerKey"
        class="intro-y col-span-12 md:col-span-6 xl:col-span-4 box dark:bg-dark-8">
        <div class="flex items-center border-b border-gray-200 dark:border-dark-5 px-5 py-4">
          <div class="w-10 h-10 flex-none image-fit">
            <img alt="Icewall Tailwind HTML Admin Template" class="rounded-full"
              :src="require(`@/assets/images/${faker.photos[0]}`)" />
          </div>
          <div class="ml-3 mr-auto">
            <a href="" class="font-medium">Ahmed Mohammed</a>
            <div class="flex text-gray-600 truncate text-xs mt-0.5">
              <a class="text-theme-17 dark:text-gray-500 inline-block truncate"
                href="javascript:;">{{i18n('recentMaterials.articles.placeholders.category')}}</a>
              <span class="mx-1">•</span> {{i18n('recentMaterials.articles.placeholders.time')}}
            </div>
          </div>
          <div class="dropdown ml-3">
            <a href="javascript:;" class="dropdown-toggle w-5 h-5 text-gray-600 dark:text-gray-300"
              aria-expanded="false">
              <MoreVerticalIcon class="w-5 h-5" />
            </a>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                  <Edit2Icon class="w-4 h-4 mx-2" /> {{i18n('recentMaterials.articles.editArticle')}}
                </a>
                <a href="javascript:;"
                  class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md">
                  <TrashIcon class="w-4 h-4 mx-2" /> {{i18n('recentMaterials.articles.deleteArticle')}}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="p-5">
          <div class="h-40 2xl:h-56 image-fit">
            <img alt="Icewall Tailwind HTML Admin Template" class="rounded-md"
              :src="require(`@/assets/images/${faker.images[0]}`)" />
          </div>
          <router-link to="/article/AQNOSsXQMWz1h1QKjDwP" class="block font-medium text-base mt-5">{{
          faker.news[0].title
          }}</router-link>
          <div class="text-gray-700 dark:text-gray-600 mt-2">
            {{ faker.news[0].shortContent }}
          </div>
        </div>
        <div class="px-5 pt-3 pb-5 border-t border-gray-200 dark:border-dark-5">
          <div class="w-full flex text-gray-600 text-xs sm:text-sm">
            <div class="mr-2">
              {{i18n('recentMaterials.articles.comments')}} <span class="font-medium">{{ faker.totals[0] }}</span>
            </div>
            <div class="mr-2">
              {{i18n('recentMaterials.articles.views')}} <span class="font-medium">{{ faker.totals[1] }}</span>
            </div>
            <div :class="isRTL ? 'mr-auto' : 'ml-auto'">
              {{i18n('recentMaterials.articles.likes')}} <span class="font-medium">{{ faker.totals[2] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style>

</style>
