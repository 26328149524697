<template>
  <div>
    <div class="flex">
      <h2 class="text-xl font-bold text-base mt-5">{{i18n('recentMaterials.images.title')}}</h2>
      <h2 class="mt-5" :class="isRTL ? 'mr-auto' : 'ml-auto'">{{i18n('recentMaterials.images.seeMore')}}</h2>
    </div>
    <div class="intro-y box dark:bg-dark-8 p-5 mt-5 flex grid grid-cols-12">
      <div class="col-span-4">
        <div class="h-60 px-2">
          <div class="h-full image-fit rounded-md overflow-hidden">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              :src="require(`@/assets/images/${$f()[0].images[0]}`)"
              data-action="zoom"
            />
          </div>
        </div>
      </div>
      <div class="col-span-4">
        <div class="h-60 px-2">
          <div class="h-full image-fit rounded-md overflow-hidden">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              :src="require(`@/assets/images/${$f()[0].images[0]}`)"
              data-action="zoom"
            />
          </div>
        </div>
      </div>
      <div class="col-span-4">
        <div class="h-60 px-2">
          <div class="h-full image-fit rounded-md overflow-hidden">
            <img
              alt="Icewall Tailwind HTML Admin Template"
              :src="require(`@/assets/images/${$f()[0].images[0]}`)"
              data-action="zoom"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
}
</script>

<style></style>
