<template>
  <div>
    <Articles />
    <Images />
    <Videos />
  </div>
</template>

<script>
import Images from '@/views/recent-materials/images.vue'
import Videos from '@/views/recent-materials/videos.vue'
import Articles from '@/views/recent-materials/articles.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    Images,
    Videos,
    Articles
  },
  computed: {
    isRTL() {
      return this.$i18n.locale == 'ar'
    }
  },
  created() {
    document.title = this.i18n('routes.app')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app')
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style></style>
